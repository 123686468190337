import React from 'react';
import { 
  FaShieldAlt, 
  FaUserLock, 
  FaChartBar, 
  FaHeadset,
  FaSearch,
  FaCheck 
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Enterprise = () => {
  const features = [
    {
      title: '엔터프라이즈급 보안',
      items: [
        'DLP(데이터 유출 방지)',
        'S/MIME 암호화',
        '고급 엔드포인트 관리',
        '컨텍스트 인식 접근 제어',
        'Vault for eDiscovery',
        '보안 관리 대시보드'
      ]
    },
    {
      title: '고급 협업 기능',
      items: [
        '화상 회의 최대 500명 참석',
        '회의 녹화 및 저장',
        '실시간 자막 및 번역',
        '노이즈 캔슬링',
        '출결 관리 기능',
        '고급 모더레이션 도구'
      ]
    },
    {
      title: '관리자 기능',
      items: [
        '데이터 통합 분석',
        '고급 감사 로그',
        '커스텀 알림 설정',
        'API 접근 제어',
        '고급 보고서 생성',
        '멀티 도메인 관리'
      ]
    }
  ];

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
      {/* 헤더 섹션 */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <span className="text-blue-600 font-semibold text-lg">
          Google Workspace
        </span>
        <h1 className="text-4xl font-bold text-gray-900 mt-2 mb-6">
          Enterprise
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          대기업을 위한 최고급 보안 및 관리 기능을 갖춘 엔터프라이즈 솔루션
        </p>
      </div>

      {/* 가격 섹션 */}
      <div className="max-w-xl mx-auto text-center mb-16">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <div className="mb-6">
            <h3 className="text-2xl font-bold text-gray-900 mb-3">
              맞춤형 견적
            </h3>
            <p className="text-gray-600 mb-2">
              기업 규모와 요구사항에 맞는 최적의 가격을 제안해드립니다
            </p>
            <div className="text-sm text-gray-500">
              <p>* 사용자 수에 따른 할인 혜택</p>
              <p>* 장기 계약 시 추가 할인</p>
            </div>
          </div>
          <div className="space-y-3">
            <a 
              href="https://forms.gle/ZKdgbNnfZRoTrZq57" 
              target="_blank" 
              rel="noopener noreferrer"
              className="block w-full"
            >
              <button className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200">
                견적 요청하기
              </button>
            </a>
            <a 
              href="http://pf.kakao.com/_jyebn/chat" 
              target="_blank" 
              rel="noopener noreferrer"
              className="block w-full"
            >
              <button className="w-full bg-white text-blue-600 py-3 px-6 rounded-lg font-semibold border-2 border-blue-600 hover:bg-blue-50 transition-colors duration-200">
                담당자 상담하기
              </button>
            </a>
          </div>
          <p className="text-sm text-gray-500 mt-4">
            평균 응답 시간: 24시간 이내
          </p>
        </div>
      </div>

      {/* 엔터프라이즈 특징 */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((section, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-8">
              <h3 className="text-xl font-bold text-gray-900 mb-6">
                {section.title}
              </h3>
              <ul className="space-y-4">
                {section.items.map((item, idx) => (
                  <li key={idx} className="flex items-start">
                    <FaCheck className="w-5 h-5 text-blue-500 mt-0.5 mr-3 flex-shrink-0" />
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* 차별화 포인트 */}
      <div className="max-w-7xl mx-auto mt-16">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Enterprise만의 차별화된 혜택
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              icon: <FaShieldAlt className="w-12 h-12" />,
              title: '최고급 보안',
              description: '엔터프라이즈급 보안 기능으로 중요 데이터를 완벽하게 보호'
            },
            {
              icon: <FaUserLock className="w-12 h-12" />,
              title: '고급 접근 제어',
              description: '세분화된 접근 권한 관리와 보안 정책 설정'
            },
            {
              icon: <FaChartBar className="w-12 h-12" />,
              title: '고급 분석',
              description: '상세한 사용 현황 분석과 인사이트 제공'
            },
            {
              icon: <FaHeadset className="w-12 h-12" />,
              title: '프리미엄 지원',
              description: '24/7 전담 기술 지원 및 신속한 대응'
            },
            {
              icon: <FaSearch className="w-12 h-12" />,
              title: 'Vault eDiscovery',
              description: '고급 데이터 보관 및 검색 기능'
            }
          ].map((item, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-8">
              <div className="text-blue-500 mb-4">
                {item.icon}
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                {item.title}
              </h3>
              <p className="text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA 섹션 */}
      <div className="max-w-4xl mx-auto mt-16 text-center">
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-2xl p-8 text-white">
          <h2 className="text-2xl font-bold mb-4">
            맞춤형 기업 솔루션이 필요하신가요?
          </h2>
          <p className="text-blue-100 mb-6">
            전문 컨설턴트가 귀사에 최적화된 솔루션을 제안해드립니다.
          </p>
          <div className="flex justify-center gap-4">
            <a 
              href="http://pf.kakao.com/_jyebn/chat"
              target="_blank" 
              rel="noopener noreferrer"
              className="block"
            >
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors duration-200">
                상담 신청하기
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enterprise;