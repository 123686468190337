import React from 'react';

const KakaoChat = () => {
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <a
        href="http://pf.kakao.com/_jyebn/chat"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-yellow-400 text-black px-4 py-2 rounded-lg shadow-lg hover:bg-yellow-500 transition flex items-center"
      >
        <img
          src="https://img.icons8.com/color/24/000000/kakaotalk.png"
          alt="카카오 아이콘"
          className="inline mr-2"
        />
        카카오 문의하기
      </a>
    </div>
  );
};

export default KakaoChat;