import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { database, storage } from "../../firebase";
import { ref as dbRef, push, set, onValue, get } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../components/AuthContext";
import { CKEditor } from 'ckeditor4-react';
import "../../css/Board.css";

const BoardCreate = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const { user } = useAuth();
  const [boardTypeName, setBoardTypeName] = useState("");
  const navigate = useNavigate();
  const { type, id } = useParams(); // id 파라미터 추가
  const isEdit = !!id; // id가 있으면 수정 모드

  useEffect(() => {
    if (!type) {
      navigate("/board/notice");
    }

    // 게시판 타입 이름 가져오기
    if (type) {
      const boardTypeRef = dbRef(database, `boardTypes/${type}`);
      onValue(boardTypeRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setBoardTypeName(data);
        }
      });
    }

    // 수정 모드일 경우 기존 게시글 데이터 가져오기
    if (isEdit) {
      const postRef = dbRef(database, `posts/${type}/${id}`);
      get(postRef).then((snapshot) => {
        if (snapshot.exists()) {
          const post = snapshot.val();
          setTitle(post.title);
          setContent(post.content);
        } else {
          alert("게시글을 찾을 수 없습니다.");
          navigate(`/board/${type}`);
        }
      });
    }
  }, [type, id, navigate, isEdit]);

  const handleSubmit = () => {
    const editorData = content;
    if (!title.trim() || !editorData.trim()) {
      alert("제목과 내용을 입력하세요!");
      return;
    }

    const postData = {
      title,
      content: editorData,
      author: user.email,
      updatedAt: new Date().toLocaleString(),
    };

    if (isEdit) {
      // 수정 모드
      const postRef = dbRef(database, `posts/${type}/${id}`);
      // 기존 데이터를 가져온 후 업데이트
      get(postRef).then((snapshot) => {
        if (snapshot.exists()) {
          const existingPost = snapshot.val();
          set(postRef, {
            ...postData,
            createdAt: existingPost.createdAt, // 기존 데이터에서 createdAt 가져오기
            views: existingPost.views || 0,
          })
            .then(() => {
              alert("게시물이 수정되었습니다!");
              navigate(`/board/${type}/${id}`);
            })
            .catch((error) => {
              console.error("글 수정 중 오류:", error);
              alert("글 수정 중 오류가 발생했습니다.");
            });
        }
      });
    } else {
      // 새 글 작성 모드 (기존 코드 유지)
      const postsRef = dbRef(database, `posts/${type}`);
      const newPostRef = push(postsRef);
      set(newPostRef, {
        ...postData,
        createdAt: postData.updatedAt,
        views: 0,
      })
        .then(() => {
          alert("게시물이 저장되었습니다!");
          navigate(`/board/${type}`);
        })
        .catch((error) => {
          console.error("글 추가 중 오류:", error);
          alert("글 저장 중 오류가 발생했습니다.");
        });
    }
  };

  return (
    <div className="board-container mt-5">
      <div className="card shadow-lg p-5 bg-light">
        <h1 className="text-center mb-4 text-primary">
          {boardTypeName} 게시판 - {isEdit ? "글 수정" : "새 글 작성"}
        </h1>
        <div className="form-group mb-4">
          <label htmlFor="title" className="form-label">
            제목
          </label>
          <input
            type="text"
            id="title"
            className="form-control form-control-lg"
            placeholder="제목을 입력하세요"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="editor" className="form-label">
            내용
          </label>
          <CKEditor
            initData={content}
            onChange={(event) => {
              const data = event.editor.getData();
              setContent(data);
            }}
            config={{
              extraPlugins: 'colorbutton,font,iframe',
              height: 400,
              toolbar: [
                { name: 'document', items: ['Source', '-', 'NewPage', 'Preview', '-', 'Templates'] },
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
                { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
                '/',
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-', 'RemoveFormat'] },
                { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak', 'Iframe'] },
                '/',
                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                { name: 'colors', items: ['TextColor', 'BGColor'] },
                { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
              ]
            }}
          />
        </div>
        <div className="text-center">
          <button
            className="btn btn-primary px-4 me-3"
            onClick={handleSubmit}
          >
            {isEdit ? "수정" : "저장"}
          </button>
          <button
            className="btn btn-secondary px-4"
            onClick={() => navigate(`/board/${type}`)}
          >
            취소
          </button>
        </div>
        <div className="content-preview" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default BoardCreate;