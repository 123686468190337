import React from 'react';
import { 
  FaChartLine, 
  FaHandshake, 
  FaShieldAlt, 
  FaClock,
  FaPiggyBank,
  FaUserFriends
} from 'react-icons/fa';

const Benefits = () => {
  const benefits = [
    {
      icon: <FaChartLine className="w-12 h-12 text-blue-500" />,
      title: '생산성 향상',
      description: '업무 효율성 증대',
      points: [
        '실시간 협업으로 업무 속도 향상',
        '통합 솔루션으로 업무 단순화',
        '자동화된 워크플로우',
        '시간과 비용 절감'
      ]
    },
    {
      icon: <FaHandshake className="w-12 h-12 text-blue-500" />,
      title: '협업 강화',
      description: '팀워크 개선',
      points: [
        '실시간 문서 공동 작업',
        '원활한 의사소통',
        '프로젝트 진행 효율화',
        '팀 생산성 극대화'
      ]
    },
    {
      icon: <FaShieldAlt className="w-12 h-12 text-blue-500" />,
      title: '보안 강화',
      description: '기업 데이터 보호',
      points: [
        '엔터프라이즈급 보안',
        '데이터 암호화',
        '접근 권한 관리',
        '감사 로그 및 보고서'
      ]
    },
    {
      icon: <FaClock className="w-12 h-12 text-blue-500" />,
      title: '시간 절약',
      description: '업무 시간 단축',
      points: [
        '자동화된 작업 처리',
        '빠른 정보 검색',
        '효율적인 일정 관리',
        '신속한 의사결정'
      ]
    },
    {
      icon: <FaPiggyBank className="w-12 h-12 text-blue-500" />,
      title: '비용 절감',
      description: 'IT 비용 최적화',
      points: [
        '하드웨어 비용 절감',
        '유지보수 비용 감소',
        '라이선스 통합 관리',
        'TCO 절감 효과'
      ]
    },
    {
      icon: <FaUserFriends className="w-12 h-12 text-blue-500" />,
      title: '업무 유연성',
      description: '하이브리드 워크 지원',
      points: [
        '언제 어디서나 업무 가능',
        '디바이스 독립적 접근',
        '원격 근무 지원',
        '실시간 협업 환경'
      ]
    }
  ];

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
      {/* 헤더 섹션 */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          Google Workspace 도입 혜택
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Google Workspace 도입을 통해 얻을 수 있는 비즈니스 혜택을 확인하세요.
        </p>
      </div>

      {/* 혜택 그리드 */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center justify-center mb-6">
                {benefit.icon}
              </div>
              <h3 className="text-2xl font-bold text-gray-900 text-center mb-4">
                {benefit.title}
              </h3>
              <p className="text-gray-600 text-center mb-6">
                {benefit.description}
              </p>
              <ul className="space-y-3">
                {benefit.points.map((point, idx) => (
                  <li key={idx} className="flex items-start">
                    <svg className="w-5 h-5 text-blue-500 mt-0.5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-600">{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* ROI 섹션 */}
      <div className="max-w-4xl mx-auto mt-16 bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl p-8 text-white">
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-6">
            Google Workspace의 ROI
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <p className="text-4xl font-bold mb-2">168%</p>
              <p className="text-blue-100">ROI 개선</p>
            </div>
            <div className="text-center">
              <p className="text-4xl font-bold mb-2">40%</p>
              <p className="text-blue-100">생산성 향상</p>
            </div>
            <div className="text-center">
              <p className="text-4xl font-bold mb-2">45%</p>
              <p className="text-blue-100">비용 절감</p>
            </div>
          </div>
          <a 
            href="http://pf.kakao.com/_jyebn/chat" 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-block"
          >
            <button className="mt-8 bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors duration-300">
              상담 신청하기
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Benefits;