import React, { useEffect, useState } from "react";
import { ref, get } from "firebase/database";
import { database } from "../firebase";
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";

const RequireAdmin = (WrappedComponent) => {
  const RequireAdminComponent = (props) => {
    const { user } = useAuth(); // Hook은 컴포넌트의 최상위에서 호출
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(null); // 권한 확인 상태

    useEffect(() => {
      if (!user) {
        navigate("/login"); // 로그인하지 않은 경우 리다이렉트
        return;
      }

      // Firebase에서 사용자 역할 확인
      const userRef = ref(database, `users/${user.uid}/role`);
      get(userRef)
        .then((snapshot) => {
          const role = snapshot.val();
          if (role === "admin") {
            setIsAdmin(true); // 관리자 권한 확인
          } else {
            navigate("/"); // 권한이 없으면 홈으로 리다이렉트
          }
        })
        .catch((error) => {
          console.error("권한 확인 중 오류:", error);
          navigate("/"); // 오류 시 홈으로 이동
        });
    }, [user, navigate]);

    // 로딩 상태 표시
    if (isAdmin === null) {
      return <div>로딩 중...</div>;
    }

    // 권한 확인 완료 후 감싸진 컴포넌트를 렌더링
    return <WrappedComponent {...props} />;
  };

  return RequireAdminComponent; // HOC는 컴포넌트를 반환해야 함
};

export default RequireAdmin;
