import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { database } from "../../firebase";
import { ref, get, update, remove } from "firebase/database";
import { useAuth } from "../../components/AuthContext";
import {
  FaCalendarAlt,
  FaUser,
  FaEye,
  FaClipboardList,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import "../../css/Board.css";

// 공통 스타일 상수
const BUTTON_BASE_STYLES = "btn";
const BUTTON_PRIMARY_STYLES = `${BUTTON_BASE_STYLES} btn-primary`;
const BUTTON_SECONDARY_STYLES = `${BUTTON_BASE_STYLES} btn-secondary`;
const BUTTON_DANGER_STYLES = `${BUTTON_BASE_STYLES} btn-danger`;

// YouTube 임베드 컴포넌트
const YouTubeEmbed = ({ videoId }) => {
  if (!videoId) return null;

  return (
    <div className="relative" style={{ paddingBottom: "56.25%" }}>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="absolute top-0 left-0 w-full h-full rounded-lg"
      />
    </div>
  );
};

// 컨텐츠 처리 유틸리티 함수들
const contentUtils = {
  extractYouTubeId: (content) => {
    if (!content) return null;
    const urlRegex = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})/;
    const match = content.match(urlRegex);
    return match ? match[1] : null;
  },

  processContent: (content) => {
    if (!content) return "";

    let processedContent = content;

    // YouTube 임베드 태그 제거
    processedContent = processedContent
      .replace(/<oembed.*?<\/oembed>/g, "")
      .trim();

    const linkStyle = "text-blue-600 hover:text-blue-800 hover:underline";
    const linkAttributes = 'target="_blank" rel="noopener noreferrer"';

    // 이미지 태그만 있는 링크 처리
    processedContent = processedContent.replace(
      /<a[^>]*>(<img[^>]*>)<\/a>/g,
      "$1"
    );

    // 이미지 태그 처리
    processedContent = processedContent.replace(
      /<img\s+src="([^"]+)"[^>]*>/g,
      '<img src="$1" class="rounded-lg max-w-full h-auto" alt="" />'
    );

    // URL 디코딩 처리
    processedContent = processedContent.replace(
      /href="([^"]+)"/g,
      (match, url) => `href="${decodeURIComponent(url.replace(/&amp;/g, "&"))}"`
    );

    // 일반 URL을 링크로 변환
    processedContent = processedContent.replace(
      /(?<!["'])(?<!href=["'])(https?:\/\/(?!youtu\.be)[^\s<>"]+)(?![^<]*>|[^<]*<\/a>)/g,
      `<a href="$1" ${linkAttributes} class="${linkStyle}">$1</a>`
    );

    return processedContent;
  },
};

const BoardDetail = () => {
  const { id, type = "notice" } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user, isAdmin } = useAuth();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postRef = ref(database, `posts/${type}/${id}`);
        const snapshot = await get(postRef);

        if (!snapshot.exists()) {
          alert("해당 게시글이 존재하지 않습니다.");
          return navigate(`/board/${type}`);
        }

        const postData = snapshot.val();
        setPost(postData);
        update(postRef, { views: (postData.views || 0) + 1 });
      } catch (error) {
        console.error("게시글 로딩 중 오류:", error);
        alert("게시글을 불러오는데 실패했습니다.");
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id, type, navigate]);

  const handleDelete = async () => {
    if (!window.confirm("정말로 이 게시글을 삭제하시겠습니까?")) return;

    try {
      await remove(ref(database, `posts/${type}/${id}`));
      navigate(`/board/${type}`);
    } catch (error) {
      console.error("삭제 중 오류:", error);
      alert("게시글 삭제에 실패했습니다.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    );
  }

  const canModify = user && (isAdmin || (post && user.email === post.author));
  const youtubeId = contentUtils.extractYouTubeId(post.content);

  return (
    <div className="board-container max-w-7xl mx-auto px-4 py-8">
      <div className="board-container bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-8">
          <h1 className="board-detail-title text-3xl font-bold text-gray-900 mb-6">
            {post.title}
          </h1>

          <div className="board-detail-meta flex flex-col sm:flex-row justify-end items-center text-sm text-gray-500 space-y-2 sm:space-y-0 sm:space-x-6 border-b border-gray-200 pb-6">
            {[
              { icon: <FaUser />, text: post.author },
              { icon: <FaCalendarAlt />, text: post.createdAt },
              { icon: <FaEye />, text: `조회수 ${post.views}` },
            ].map(({ icon, text }, index) => (
              <span key={index} className="flex items-center">
                <span className="mr-2">{icon}</span> {text}
              </span>
            ))}
          </div>

          <div className="board-detail-content mt-8 prose max-w-none overflow-x-auto">
            {youtubeId && (
              <div className="mb-6">
                <YouTubeEmbed videoId={youtubeId} />
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: contentUtils.processContent(post.content),
              }}
              className="space-y-8 text-gray-800 text-md sm:text-lg leading-relaxed"
              style={{ lineHeight: "2.2", letterSpacing: "0.025em" }}
            />
          </div>
        </div>
      </div>

      <div className="board-detail-buttons mt-6 flex flex-col sm:flex-row justify-end items-center space-y-2 sm:space-y-0 sm:space-x-3">
        <button
          onClick={() => navigate(`/board/${type}`)}
          className="btn btn-secondary flex items-center px-4 py-2 rounded-lg shadow-md hover:bg-gray-200 transition duration-300"
        >
          <FaClipboardList className="mr-2" /> 목록으로
        </button>

        {canModify && (
          <>
            <button
              onClick={() => navigate(`/board/${type}/edit/${id}`)}
              className="btn btn-primary flex items-center px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
            >
              <FaEdit className="mr-2" /> 수정
            </button>
            <button
              onClick={handleDelete}
              className="btn btn-danger flex items-center px-4 py-2 rounded-lg shadow-md hover:bg-red-600 transition duration-300"
            >
              <FaTrash className="mr-2" /> 삭제
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default BoardDetail;
