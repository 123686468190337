import React, { useState } from 'react';
import "../../css/ProductInfo.css";
import expense from "../../images/mobile/4.jpg";
import expense1 from "../../images/mobile/4-1.jpg";
import expense2 from "../../images/mobile/4-2.jpg";
import contacts from "../../images/mobile/1.jpg";
import contacts1 from "../../images/mobile/1-1.jpg";
import clients from "../../images/mobile/3.jpg";

import attendance from "../../images/mobile/2.jpg";
import attendance1 from "../../images/mobile/2-1.jpg";
import attendance2 from "../../images/mobile/2-2.jpg";

const WorkAppsheet = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState(null);

  const products = [
    {
      title: '비용 처리 시스템',
      description: '간편한 경비 청구와 승인 프로세스',
      features: [
        '실시간 경비 청구 및 승인',
        '영수증 이미지 첨부 기능',
        '자동 인식(상점,비용,일자)',        
      ],
      images: [expense, expense1, expense2] // 실제 이미지로 교체 필요
    },
    {
      title: '사내 연락처 관리',
      description: '임직원 정보의 통합 관리 솔루션',
      features: [
        '구성원 관리',
        '임직원 검색 기능',        
      ],
      images: [contacts, contacts1] // 실제 이미지로 교체 필요
    },
    {
      title: '거래처 관리 시스템',
      description: '거래처 정보 및 이력 통합 관리',
      features: [
        '거래처 정보 데이터베이스',
        '거래처 이력 추적',
      ],
      images: [clients] // 실제 이미지로 교체 필요
    },
    {
      title: '출/퇴근 기록 시스템',
      description: '효율적인 근태 관리 솔루션',
      features: [
        'GPS 기반 출/퇴근 기록',
      ],
      images: [attendance, attendance1, attendance2] // 실제 이미지로 교체 필요
    }
  ];

  const handleImageClick = (image, product, index) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
    setCurrentProduct(product);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    if (currentImageIndex > 0) {
      setSelectedImage(currentProduct.images[currentImageIndex - 1]);
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    if (currentImageIndex < currentProduct.images.length - 1) {
      setSelectedImage(currentProduct.images[currentImageIndex + 1]);
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  return (
    <div className="py-8 sm:py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">
            AppSheet 기반 비즈니스 솔루션
          </h2>
          <p className="mt-3 sm:mt-4 text-base sm:text-lg text-gray-600">
            Google AppSheet로 구현된 맞춤형 업무 시스템으로 업무 효율을 높이세요.
          </p>
        </div>

        <div className="mt-8 sm:mt-16 grid gap-6 sm:gap-8 grid-cols-1 md:grid-cols-2">
          {products.map((product, index) => (       
            <div
              key={index}
              className="bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden"
            >
              <div className="grid grid-cols-3 gap-3 p-4 bg-gray-100">
                {product.images.map((image, imgIndex) => (
                  <div 
                    key={imgIndex}
                    className="relative aspect-square cursor-pointer group bg-white rounded-lg overflow-hidden"
                    onClick={() => handleImageClick(image, product, imgIndex)}
                  >
                    <img
                      src={image}
                      alt={`${product.title} ${imgIndex + 1}`}
                      className="w-full h-full object-contain p-2 transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
                  </div>
                ))}
              </div>
              
              <div className="p-6">
                <h3 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-2">
                  {product.title}                
                </h3>
                <p className="text-sm sm:text-base text-gray-600 mb-4">
                  {product.description}
                </p>
                <ul className="space-y-2">
                  {product.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start text-sm sm:text-base">
                      <svg
                        className="h-5 w-5 sm:h-6 sm:w-6 text-blue-500 mr-2 flex-shrink-0 mt-0.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* 이미지 모달 */}
        {selectedImage && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
            onClick={() => {
              setSelectedImage(null);
              setCurrentImageIndex(0);
              setCurrentProduct(null);
            }}
          >
            <div className="relative max-w-3xl w-full max-h-[80vh] bg-white rounded-lg p-0">
              <button
                className="absolute -top-10 right-0 text-white hover:text-gray-300 z-3"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImage(null);
                  setCurrentImageIndex(0);
                  setCurrentProduct(null);
                }}
              >
                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

              {/* 이전 버튼 */}
              {currentImageIndex > 0 && (
                <button
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors border-5"
                  onClick={handlePrevImage}
                >
                  <svg className="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
              )}

              {/* 이미지 */}
              <div className="w-full h-full flex items-center justify-center">
                <img
                  src={selectedImage}
                  alt="확대된 이미지"
                  className="max-w-full max-h-[70vh] object-contain"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>

              {/* 다음 버튼 */}
              {currentProduct && currentImageIndex < currentProduct.images.length - 1 && (
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors border-5"
                  onClick={handleNextImage}
                >
                  <svg className="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              )}

              {/* 이미지 카운터 */}
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm">
                {currentImageIndex + 1} / {currentProduct?.images.length}
              </div>
            </div>
          </div>
        )}

 
      </div>
    </div>
  );
};

export default WorkAppsheet;