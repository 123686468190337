import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../css/Navbar.css";
import logo from "../../images/logo/workway_1000.png";
import { useAuth } from "../../components/AuthContext";
import {
  FaCog,
  FaUserCircle,
  FaChevronDown,
  FaSignOutAlt,
} from "react-icons/fa";

const menuItems = [
  { to: "/", label: "홈" },
  {
    label: "GWS 소개",
    to: "/serviceintro",
    submenu: [
      { to: "/serviceintro/overview", label: "서비스 개요" },
      { to: "/serviceintro/features", label: "주요 기능" },
      { to: "/serviceintro/benefits", label: "도입 혜택" },
     
    ],
  },
  {
    label: "제품 안내",
    to: "/productinfo/business",
    submenu: [
      { to: "/productinfo/business", label: "Business" },
      { to: "/productinfo/enterprise", label: "Enterprise" },
      { to: "/productinfo/pricing", label: "가격 정책" },
    ],
  },
  {
    label: "Workway 도입 혜택",
    to: "/productinfo/workappsheet",
    submenu: [
      { to: "/serviceintro/workappsheet", label: "회사관리 시스템" },      
    ],
  },
  {
    label: "커뮤니티",
    to: "/board/notice",
    submenu: [
      { to: "/board/notice", label: "공지사항" },
      { to: "/board/update", label: "구글 업데이트" },      
      { to: "/board/faq", label: "자주 묻는 질문" },
    ],
  },
  { 
    href: "https://blog.naver.com/work-way",
    label: "GWS 소식(블로그)",
    external: true 
  },
  {
    href: "https://forms.gle/ZKdgbNnfZRoTrZq57",
    label: "무료 체험",
    external: true
  },
];

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { user, loginWithGoogle, logout, isAdmin } = useAuth();
  const location = useLocation();
  const userMenuRef = useRef(null);

  // 외부 클릭 핸들러
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // 메뉴 토글 핸들러
  const closeMenu = () => setIsMobileMenuOpen(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(prev => !prev);
  

  // 로그아웃 핸들러
  const handleLogout = () => {
    logout();
    setIsUserMenuOpen(false);
  };

  // 네비게이션 링크 렌더링
  const renderNavLinks = () => (
    <>
      {menuItems.map((item, index) => (
        <NavItem
          key={index}
          {...item}
          onClick={closeMenu}
        />
      ))}
    </>
  );

  // 로고 섹션
  const LogoSection = () => (
    <div className="navbar-logo">
      <Link to="/" aria-label="Go to homepage">
        <img src={logo} alt="WORKWAY Logo" className="h-8 sm:h-10" />
      </Link>
    </div>
  );

  // 모바일 버튼 그룹
  const MobileButtons = () => (
    <div className="flex items-center h-full gap-2 2xl:hidden">
      <button
        className="mobile-user-btn flex items-center gap-2"
        onClick={user ? handleLogout : loginWithGoogle}
      >
        {user ? (
          <>
            <span className="text-sm font-medium truncate max-w-[100px]">
              {user.displayName || "사용자"}님
            </span>
            <FaUserCircle className="w-6 h-6 text-blue-500 flex-shrink-0" />
          </>
        ) : (
          <FaUserCircle className="w-6 h-6 text-gray-400" />
        )}
      </button>
      <button className="hamburger-icon" onClick={toggleMobileMenu}>
        ☰
      </button>
    </div>
  );

  // DesktopAuthSection 컴포넌트
  const DesktopAuthSection = () => (
    <div className="auth-section xl:block hidden">
      {user ? (
        <UserMenu 
          user={user} 
          isAdmin={isAdmin}
          onLogout={handleLogout}
          ref={userMenuRef}
        />
      ) : (
        <button 
          onClick={loginWithGoogle} 
          className="flex items-center px-6 py-2.5 border-2 border-blue-500 rounded-lg text-blue-600 hover:bg-blue-100 hover:text-white transition-all duration-200 font-semibold shadow-sm hover:shadow-md"
        >
          <FaUserCircle className="w-5 h-5 mr-2 text-blue-500 group-hover:text-white" />
          <span>로그인</span>
        </button>
      )}
    </div>
  );

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <LogoSection />
        <MobileButtons />
        <ul className={`nav-links ${isMobileMenuOpen ? "mobile-open" : ""}`}>
          {renderNavLinks()}
        </ul>
        <DesktopAuthSection />
      </nav>
    </div>
  );
}

// NavItem 컴포넌트
const NavItem = ({ to, href, label, submenu, external, onClick }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation();
  const isMobile = window.innerWidth <= 768;
  
  // 현재 경로가 서브메뉴 항목과 일치하는지 확인
  const isCurrentPath = to && (
    location.pathname === to || 
    (submenu && submenu.some(item => location.pathname === item.to))
  );

  // 서브메뉴가 활성화되어야 하는지 확인
  const shouldShowSubmenu = submenu && (
    location.pathname.startsWith(to) || 
    submenu.some(item => location.pathname === item.to)
  );

  // 경로 변경시 서브메뉴 상태 업데이트
  useEffect(() => {
    if (shouldShowSubmenu && !isMobile) {
      setIsSubMenuOpen(true);
    } else {
      setIsSubMenuOpen(false);
    }
  }, [location.pathname, shouldShowSubmenu, isMobile]);

  const linkClass = `nav-link text-base font-semibold hover:text-blue-600 transition-colors ${
    isCurrentPath ? "active text-blue-600" : "text-gray-700"
  }`;

  const handleClick = (e) => {
    if (submenu) {
      e.preventDefault();
      setIsSubMenuOpen(!isSubMenuOpen);
    } else {
      onClick?.();
    }
  };

  const handleSubMenuClick = () => {
    onClick?.();
    if (!isMobile) {
      setIsSubMenuOpen(false);
    }
  };

  return (
    <li
      className="relative nav-item" // nav-item 클래스 추가
      onMouseEnter={() => !isMobile && submenu && setIsSubMenuOpen(true)}
      onMouseLeave={() => !isMobile && submenu && setIsSubMenuOpen(false)}
    >
      {external ? (
        <a 
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={`${linkClass} flex items-center justify-between w-full`}
        >
          <span>{label}</span>
        </a>
      ) : (
        <Link
          to={to}
          className={`${linkClass} flex items-center justify-between w-full p-2`} // padding 추가
          onClick={handleClick}
        >
          <span>{label}</span>
          {submenu && (
            <FaChevronDown 
              className={`w-3 h-3 transition-transform duration-300 ${
                isSubMenuOpen ? 'rotate-180' : ''
              }`} 
            />
          )}
        </Link>
      )}
      {submenu && (
        <ul className={`
          submenu-container
          bg-white transition-all duration-300 ease-in-out
          ${isSubMenuOpen ? 'block' : 'hidden'}
          ${isMobile 
            ? 'relative w-full pl-4 py-2 space-y-2' 
            : 'absolute left-0 top-full w-48 shadow-lg rounded-md py-2 z-50'
          }
        `}>
          {submenu.map((item, index) => (
            <li key={index}>
              <Link
                to={item.to}
                className={`block px-4 py-2 text-sm transition-colors duration-200 ${
                  location.pathname === item.to
                    ? "text-blue-600 bg-blue-50"
                    : "text-gray-700 hover:bg-blue-50 hover:text-blue-600"
                }`}
                onClick={handleSubMenuClick}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

// UserMenu 컴포넌트
const UserMenu = React.forwardRef(({ user, isAdmin, onLogout }, ref) => (
  <div className="user-info flex items-center gap-3" ref={ref}>
    <div className="flex items-center gap-2">
      <FaUserCircle className="w-6 h-6 text-blue-500" />
      <span className="text-base font-semibold">
        {user.displayName || "사용자"}님
      </span>
    </div>
    {isAdmin && (
      <Link
        to="/workadmin"
        className="flex items-center text-gray-700 hover:text-blue-600 transition-colors"
      >
        <FaCog className="w-5 h-5" />
      </Link>
    )}
    <button
      onClick={onLogout}
      className="flex items-center text-gray-700 hover:text-red-600 transition-colors"
    >
      <FaSignOutAlt className="w-5 h-5" />
    </button>
  </div>
));

export default Navbar;
