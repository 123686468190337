import React from 'react';
import { 
  FaEnvelope, 
  FaVideo, 
  FaCalendar, 
  FaFile,
  FaCloud,
  FaLock,
  FaSearch,
  FaMobile
} from 'react-icons/fa';

const Features = () => {
  const features = [
    {
      icon: <FaEnvelope />,
      title: 'Gmail for Business',
      description: '기업용 이메일 서비스',
      details: [
        '도메인 기반의 전문적인 이메일 주소',
        '스팸 및 악성코드 차단',
        '메일 보관 및 복구 기능',
        '통합 검색 시스템'
      ]
    },
    {
      icon: <FaVideo />,
      title: 'Google Meet',
      description: '화상 회의 솔루션',
      details: [
        '최대 250명 동시 접속',
        '화면 공유 및 실시간 자막',
        '회의 녹화 기능',
        '캘린더 연동'
      ]
    },
    {
      icon: <FaCalendar />,
      title: 'Google Calendar',
      description: '일정 관리 도구',
      details: [
        '팀 일정 공유',
        '회의실 예약 시스템',
        '외부 캘린더 연동',
        '자동 일정 조율'
      ]
    },
    {
      icon: <FaFile />,
      title: 'Google Workspace',
      description: '문서 작성 도구',
      details: [
        'Docs, Sheets, Slides',
        '실시간 공동 작업',
        '자동 저장',
        '버전 관리'
      ]
    },
    {
      icon: <FaCloud />,
      title: 'Google Drive',
      description: '클라우드 스토리지',
      details: [
        '무제한 스토리지',
        '파일 공유 및 권한 관리',
        '오프라인 동기화',
        '강력한 검색 기능'
      ]
    },
    {
      icon: <FaLock />,
      title: '보안 기능',
      description: '기업 데이터 보호',
      details: [
        '2단계 인증',
        '데이터 암호화',
        '모바일 기기 관리',
        '감사 로그'
      ]
    },
    {
      icon: <FaSearch />,
      title: '통합 검색',
      description: '기업 데이터 검색',
      details: [
        '전체 콘텐츠 검색',
        '실시간 검색 결과',
        '고급 필터링',
        '보안 설정 반영'
      ]
    },
    {
      icon: <FaMobile />,
      title: '모바일 지원',
      description: '모바일 업무 환경',
      details: [
        '모바일 앱 제공',
        '크로스 플랫폼 지원',
        '실시간 동기화',
        '보안 정책 적용'
      ]
    }
  ];

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
      {/* 헤더 섹션 */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          Google Workspace 주요 기능
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          기업의 생산성과 협업을 높이는 Google Workspace의 핵심 기능을 소개합니다.
        </p>
      </div>

      {/* 기능 그리드 */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="text-blue-500 text-3xl mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600 mb-4">{feature.description}</p>
              <ul className="space-y-2">
                {feature.details.map((detail, idx) => (
                  <li key={idx} className="text-sm text-gray-500 flex items-center">
                    <span className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></span>
                    {detail}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* CTA 섹션 */}
      <div className="max-w-4xl mx-auto mt-16">
        <div className="bg-blue-600 rounded-2xl p-8 text-center text-white">
          <h2 className="text-2xl font-bold mb-4">
            무료로 시작해보세요
          </h2>
          <p className="mb-6 text-blue-100">
          30일 동안 모든 기능을 제한 없이 사용해볼 수 있습니다.
          </p>
          <a 
            href="https://forms.gle/ZKdgbNnfZRoTrZq57" 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-block"
          >
            <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors duration-300">
              무료 체험 신청
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Features;