import React from "react";
import "../../css/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-info">
          <p>회사명: WorkWay | 대표자: 채주연</p>
          <p>
            문의 메일:{" "}
            <a href="mailto:workway.help@workway.co.kr">workway.help@workway.co.kr</a>
          </p>
          <p>주소: 서울특별시 영등포구 국회대로36길 6-1 (당산동3가), 2층-제이15호 (07257)</p>
          <p>사업자등록번호: 169-86-03431</p>
        </div>
        <div className="copyright-info">
          <p>&copy; 2024 WorkWay. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
