import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, set, remove } from "firebase/database";
import RequireAdmin from "../../hoc/RequireAdmin";

const BoardTypeManager = () => {
  const [boardTypes, setBoardTypes] = useState({});
  const [newKey, setNewKey] = useState(""); // 새로운 키 입력
  const [newValue, setNewValue] = useState(""); // 새로운 값 입력

  // Firebase에서 게시판 이름 데이터 가져오기
  useEffect(() => {
    const boardTypesRef = ref(database, "boardTypes");
    onValue(boardTypesRef, (snapshot) => {
      const data = snapshot.val();
      setBoardTypes(data || {});
    });
  }, []);

  // 새로운 게시판 이름 추가
  const addBoardType = () => {
    if (!newKey.trim() || !newValue.trim()) {
      alert("키와 값을 모두 입력하세요!");
      return;
    }
    const boardTypesRef = ref(database, `boardTypes/${newKey}`);
    set(boardTypesRef, newValue)
      .then(() => {
        setNewKey("");
        setNewValue("");
      })
      .catch((error) => console.error("추가 중 오류:", error));
  };

  // 게시판 이름 삭제
  const deleteBoardType = (key) => {
    const boardTypeRef = ref(database, `boardTypes/${key}`);
    remove(boardTypeRef).catch((error) => console.error("삭제 중 오류:", error));
  };

  // 게시판 이름 수정
  const updateBoardType = (key, value) => {
    const boardTypeRef = ref(database, `boardTypes/${key}`);
    set(boardTypeRef, value).catch((error) =>
      console.error("수정 중 오류:", error)
    );
  };

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white shadow-lg rounded-md">
      <h2 className="text-3xl font-bold text-center mb-6 text-gray-700">
        게시판 이름 관리
      </h2>

      {/* Add New Board */}
      <div className="mb-6 bg-gray-50 p-4 rounded-md shadow">
        <h4 className="text-lg font-semibold mb-4 text-gray-600">
          새로운 게시판 추가
        </h4>
        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="키 (예: notice)"
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
            className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
          />
          <input
            type="text"
            placeholder="값 (예: 공지사항)"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
          />
          <button
            onClick={addBoardType}
            className="bg-indigo-500 text-white px-6 py-2 rounded-md shadow hover:bg-indigo-600 transition duration-200"
          >
            추가
          </button>
        </div>
      </div>

      {/* Existing Boards */}
      <h4 className="text-lg font-semibold mb-4 text-gray-600">
        기존 게시판 목록
      </h4>
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse bg-gray-50 rounded-lg shadow-md overflow-hidden">
          <thead className="bg-indigo-100 text-gray-700">
            <tr>
              <th className="border border-gray-300 px-4 py-3 text-left">키</th>
              <th className="border border-gray-300 px-4 py-3 text-left">값</th>
              <th className="border border-gray-300 px-4 py-3 text-center">관리</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(boardTypes).map(([key, value], index) => (
              <tr
                key={key}
                className={`${
                  index % 2 === 0 ? "bg-white" : "bg-gray-100"
                } hover:bg-indigo-50 transition duration-150`}
              >
                <td className="border border-gray-300 px-4 py-3">{key}</td>
                <td className="border border-gray-300 px-4 py-3">
                  <input
                    type="text"
                    defaultValue={value}
                    onBlur={(e) => updateBoardType(key, e.target.value)}
                    className="w-full px-2 py-[6px] border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                  />
                </td>
                <td className="border border-gray-300 px-4 py-3 text-center">
                  <button
                    onClick={() => deleteBoardType(key)}
                    className="bg-red-500 text-white px-[10px] py-[6px] rounded-md shadow hover:bg-red-600 transition duration-200"
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RequireAdmin(BoardTypeManager);
