import React from 'react';
import { 
  FaCheck, 
  FaUsers, 
  FaCloud, 
  FaShieldAlt,
  FaHeadset 
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Business = () => {
  const features = [
    {
      title: '기본 기능',
      items: [
        '맞춤 비즈니스 이메일',
        '화상 회의 (최대 150명)',
        '보안 메시지',
        '공유 캘린더',
        '30GB 클라우드 스토리지',
        '문서/스프레드시트/프레젠테이션',
        '공유 드라이브'
      ]
    },
    {
      title: '보안 및 관리',
      items: [
        '기본 관리자 제어',
        '표준 보안',
        '2단계 인증',
        '엔드포인트 관리',
        '기본 감사 로그',
        '이메일 보관'
      ]
    },
    {
      title: '지원',
      items: [
        '온라인 고객 지원',
        '전화 및 이메일 지원',
        '표준 배포 서비스',
        '온라인 교육 자료'
      ]
    }
  ];

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
      {/* 헤더 섹션 */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <span className="text-blue-600 font-semibold text-lg">
          Google Workspace
        </span>
        <h1 className="text-4xl font-bold text-gray-900 mt-2 mb-6">
          Business Standard
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          중소기업에 적합한 비즈니스 솔루션으로, 
          안전한 협업과 생산성 향상을 위한 모든 도구를 제공합니다.
        </p>
      </div>

      {/* 가격 섹션 */}
      <div className="max-w-xl mx-auto text-center mb-16">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <div className="mb-4">
            <div className="flex items-center justify-center gap-3 mb-2">
              <span className="text-5xl font-bold text-gray-900">$12.60</span>
              <span className="text-xl text-gray-600">USD</span>
            </div>
            <div className="text-gray-600">사용자당 매월, 1년 약정 시</div>
          </div>
          <div className="text-sm text-gray-500 mb-6">
            <span className="line-through">$14 USD</span>
            <span className="ml-2">10% 할인 적용</span>
          </div>
          <a 
            href="https://forms.gle/ZKdgbNnfZRoTrZq57" 
            target="_blank" 
            rel="noopener noreferrer"
            className="block w-full"
          >
            <button className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200">
              무료 체험 시작하기
            </button>
          </a>
          <p className="text-sm text-gray-500 mt-4">
            30일 동안 무료로 체험해 보세요
          </p>
        </div>
      </div>

      {/* 특징 섹션 */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((section, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-8">
              <h3 className="text-xl font-bold text-gray-900 mb-6">
                {section.title}
              </h3>
              <ul className="space-y-4">
                {section.items.map((item, idx) => (
                  <li key={idx} className="flex items-start">
                    <FaCheck className="w-5 h-5 text-blue-500 mt-0.5 mr-3 flex-shrink-0" />
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* 장점 섹션 */}
      <div className="max-w-7xl mx-auto mt-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: <FaUsers />,
              title: '팀 협업',
              description: '실시간 협업 도구로 팀 생산성 향상'
            },
            {
              icon: <FaCloud />,
              title: '클라우드 스토리지',
              description: '안전한 데이터 저장 및 공유'
            },
            {
              icon: <FaShieldAlt />,
              title: '보안',
              description: '기업 데이터 보호를 위한 보안 기능'
            },
            {
              icon: <FaHeadset />,
              title: '기술 지원',
              description: '24/7 전문가 기술 지원'
            }
          ].map((item, index) => (
            <div key={index} className="text-center">
              <div className="text-blue-500 text-3xl mb-4">
                {item.icon}
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {item.title}
              </h3>
              <p className="text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA 섹션 */}
      <div className="max-w-4xl mx-auto mt-16 text-center">
        <div className="bg-blue-50 rounded-2xl p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            지금 바로 시작하세요
          </h2>
          <p className="text-gray-600 mb-6">
            Google Workspace Business로 비즈니스 성장을 가속화하세요
          </p>
          <div className="flex justify-center gap-4">
            <a 
              href="https://forms.gle/ZKdgbNnfZRoTrZq57" 
              target="_blank" 
              rel="noopener noreferrer"
              className="block"
            >
              <button className="bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200">
                무료 체험 신청
              </button>
            </a>
            <a 
              href="http://pf.kakao.com/_jyebn/chat" 
              target="_blank" 
              rel="noopener noreferrer"
              className="block"
            >
              <button className="bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold border-2 border-blue-600 hover:bg-blue-50 transition-colors duration-200">
                상담 문의
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;