import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Features from "./pages/Features";
import Solutions from "./pages/Solutions";


import Navbar from "./pages/Common/Navbar";
import Board from "./pages/Board/Board";
import BoardCreate from "./pages/Board/BoardCreate";
import BoardDetail from "./pages/Board/BoardDetail";
import BoardTypeManager from "./pages/Admin/BoardTypeManager";
import Workway from "./pages/Workway";
import Footer from "./pages/Common/Footer";
import ServiceIntro from "./pages/ServiceIntro";

import SchedulePage from "./pages/SchedulePage";

import { AuthProvider } from "./components/AuthContext";
import AddAdmin from './pages/Admin/AddAdmin';
import AdminDashboard from './pages/Admin/AdminDashboard';
import KakaoChat from './components/KakaoChat';

import Overview from './pages/serviceintro/Overview';
import Features1 from './pages/serviceintro/Features';
import Benefits from './pages/serviceintro/Benefits';
import Business from './pages/productinfo/Business';
import Enterprise from './pages/productinfo/Enterprise';
import Pricing from './pages/productinfo/Pricing';
import WorkAppsheet  from './pages/serviceintro/workAppsheet';


function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/solutions" element={<Solutions />} />            
            
            <Route path="/board" element={<Board />} />
            <Route path="/board/:type" element={<Board />} />
            <Route path="/board/:type/add" element={<BoardCreate />} />          
            <Route path="/board/:type/edit/:id" element={<BoardCreate />} />
            <Route path="/board/:type/:id" element={<BoardDetail />} />
            <Route path="/board/notice" element={<Board type="notice" />} />
            <Route path="/board/update" element={<Board type="update" />} />
            <Route path="/board/event" element={<Board type="event" />} />
            <Route path="/board/faq" element={<Board type="faq" />} />
            <Route path="/board-types" element={<BoardTypeManager />} /> {/* 관리 페이지 */}
            <Route path="/addadmin" element={<AddAdmin />} /> {/* 관리 페이지 */}
            <Route path="/workadmin/*" element={<AdminDashboard />} />
            <Route path="/workway" element={<Workway />} />
            <Route path="/serviceintro" element={<ServiceIntro />} />
            
            <Route path="/schedulepage" element={<SchedulePage />} />
            
            <Route path="/serviceintro/workappsheet" element={<WorkAppsheet />} />
            <Route path="/serviceintro/overview" element={<Overview />} />
            <Route path="/serviceintro/features" element={<Features1 />} />
            <Route path="/serviceintro/benefits" element={<Benefits />} />
            <Route path="/productinfo/business" element={<Business />} />
            <Route path="/productinfo/enterprise" element={<Enterprise />} />
            <Route path="/productinfo/pricing" element={<Pricing />} />
          </Routes>
          <Footer />
        </AuthProvider>
      </Router>
      <KakaoChat />
    </div>
  );
}

export default App;
