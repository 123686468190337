import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import {
  FaGoogle,
  FaCloud,
  FaUsers,
  FaChartLine,
  FaShieldAlt,
} from "react-icons/fa";
import LoadingSpinner from "../components/LoadingSpinner";
import "../css/home.css";
import { database } from "../firebase";
import { ref, onValue } from "firebase/database";

const Slider = lazy(() => import("./Common/Slide"));
const Popup = lazy(() => import("../components/Popup"));

// formatDate 함수 추가
const formatDate = (timestamp) => {
  if (!timestamp) return "";

  try {
    if (typeof timestamp === "string") {
      const parts = timestamp.split(".");
      if (parts.length >= 3) {
        const year = parts[0].trim();
        const month = parts[1].trim();
        const day = parts[2].trim();
        return `${year}. ${month}. ${day}`;
      }
    }

    const date = new Date(timestamp);
    if (!isNaN(date.getTime())) {
      return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`;
    }

    return "";
  } catch (error) {
    console.error("날짜 변환 오류:", error, "timestamp:", timestamp);
    return "";
  }
};

const ImageWithLoading = ({ src, alt, className }) => (
  <img src={src} alt={alt} className={className} loading="lazy" />
);

const MemoizedStatCard = React.memo(({ icon, title, description }) => (
  <div className="text-center p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
    <div className="text-blue-500 text-2xl md:text-3xl mb-4">{icon}</div>
    <h3 className="text-base md:text-xl font-semibold text-gray-900 mb-2">
      {title}
    </h3>
    <p className="text-xs md:text-base text-gray-600">{description}</p>
  </div>
));

function Home() {
  const [noticePosts, setNoticePosts] = useState([]);
  const [updatePosts, setUpdatePosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const noticeRef = ref(database, "posts/notice");
        const updateRef = ref(database, "posts/update");

        // 병렬로 데이터 가져오기
        const [noticeSnapshot, updateSnapshot] = await Promise.all([
          new Promise((resolve) =>
            onValue(noticeRef, resolve, { onlyOnce: true })
          ),
          new Promise((resolve) =>
            onValue(updateRef, resolve, { onlyOnce: true })
          ),
        ]);

        const noticeData = noticeSnapshot.val();
        const updateData = updateSnapshot.val();

        setNoticePosts(
          noticeData
            ? Object.entries(noticeData)
                .reverse()
                .map(([id, value]) => ({
                  id,
                  ...value,
                  createdAt: value.createdAt,
                }))
                .slice(0, 5)
            : []
        );

        setUpdatePosts(
          updateData
            ? Object.entries(updateData)
                .reverse()
                .map(([id, value]) => ({
                  id,
                  ...value,
                  createdAt: value.createdAt,
                }))
                .slice(0, 5)
            : []
        );
      } catch (error) {
        console.error("데이터 로딩 중 오류:", error);
      }
    };

    fetchPosts();
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const hideUntil = localStorage.getItem("hidePopupUntil");
    if (hideUntil && new Date().getTime() < Number(hideUntil)) {
      setIsPopupOpen(false);
    } else {
      setIsPopupOpen(true);
    }
  }, []);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const dontShowForDay = () => {
    const oneDayMs = 86400000;
    localStorage.setItem("hidePopupUntil", new Date().getTime() + oneDayMs);
    closePopup();
  };

  return (
    <div className="home">
      <header className="mb-8 md:mb-12">
        <Suspense fallback={<LoadingSpinner />}>
          <Slider />
        </Suspense>
      </header>
      <main className="space-y-8 md:space-y-16">
        <section className="container mx-auto px-4">
          <h2 className="text-xl md:text-3xl font-bold text-center text-gray-900 mb-8 md:mb-12">
            Google Workspace로 비즈니스의 혁신을 시작하세요
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
            <div className="bg-white rounded-xl shadow-lg p-4 md:p-8 hover:shadow-xl transition-shadow">
              <div className="text-blue-500 text-3xl md:text-4xl mb-2 md:mb-4 text-center">
                <FaGoogle />
              </div>
              <h3 className="text-base md:text-xl font-bold text-gray-900 mb-2 md:mb-4 text-center">
                스마트한 업무 환경
              </h3>
              <p className="text-sm md:text-base text-gray-600 text-center mb-3 md:mb-6">
                이메일부터 화상회의까지 하나의 솔루션으로
              </p>
              <Link
                to="/serviceintro/overview"
                className="block text-center text-blue-600 hover:text-blue-700 text-sm md:text-base"
              >
                자세히 보기 →
              </Link>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-4 md:p-8 hover:shadow-xl transition-shadow">
              <div className="text-blue-500 text-3xl md:text-4xl mb-2 md:mb-4 text-center">
                <FaCloud />
              </div>
              <h3 className="text-base md:text-xl font-bold text-gray-900 mb-2 md:mb-4 text-center">
                클라우드 스토리지
              </h3>
              <p className="text-sm md:text-base text-gray-600 text-center mb-3 md:mb-6">
                기업 데이터를 안전하게 저장하고 공유하세요
              </p>
              <Link
                to="/serviceintro/features"
                className="block text-center text-blue-600 hover:text-blue-700 text-sm md:text-base"
              >
                자세히 보기 →
              </Link>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-4 md:p-8 hover:shadow-xl transition-shadow">
              <div className="text-blue-500 text-3xl md:text-4xl mb-2 md:mb-4 text-center">
                <FaUsers />
              </div>
              <h3 className="text-base md:text-xl font-bold text-gray-900 mb-2 md:mb-4 text-center">
                기업 협업 솔루션
              </h3>
              <p className="text-sm md:text-base text-gray-600 text-center mb-3 md:mb-6">
                실시간 협업으로 업무 효율성을 높이세요
              </p>
              <Link
                to="/serviceintro/benefits"
                className="block text-center text-blue-600 hover:text-blue-700 text-sm md:text-base"
              >
                자세히 보기 →
              </Link>
            </div>
          </div>
        </section>

        <section className="bg-gray-50 py-8 md:py-16">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8">
              <div className="bg-white rounded-xl shadow-lg p-4 md:p-6">
                <div className="flex justify-between items-center mb-3 md:mb-6">
                  <h3 className="text-lg md:text-xl font-bold text-gray-900">
                    공지사항
                  </h3>
                  <Link
                    to="/board/notice"
                    className="text-blue-600 hover:text-blue-700 text-sm md:text-base"
                  >
                    더보기 →
                  </Link>
                </div>
                <ul className="space-y-2 md:space-y-4">
                  {noticePosts.map((post) => (
                    <li
                      key={post.id}
                      className="flex justify-between items-center py-1 md:py-2 border-b border-gray-100"
                    >
                      <Link
                        to={`/board/notice/${post.id}`}
                        className="text-sm md:text-gray-800 hover:text-blue-600 cursor-pointer truncate pr-2 md:pr-4"
                      >
                        {post.title}
                      </Link>
                      <span className="text-gray-500 text-xs md:text-sm whitespace-nowrap">
                        {formatDate(post.createdAt)}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="bg-white rounded-xl shadow-lg p-4 md:p-6">
                <div className="flex justify-between items-center mb-3 md:mb-6">
                  <h3 className="text-lg md:text-xl font-bold text-gray-900">
                    구글 업데이트
                  </h3>
                  <Link
                    to="/board/update"
                    className="text-blue-600 hover:text-blue-700 text-sm md:text-base"
                  >
                    더보기 →
                  </Link>
                </div>
                <ul className="space-y-2 md:space-y-4">
                  {updatePosts.map((post) => (
                    <li
                      key={post.id}
                      className="flex justify-between items-center py-1 md:py-2 border-b border-gray-100"
                    >
                      <Link
                        to={`/board/update/${post.id}`}
                        className="text-sm md:text-gray-800 hover:text-blue-600 cursor-pointer truncate pr-2 md:pr-4"
                      >
                        {post.title}
                      </Link>
                      <span className="text-gray-500 text-xs md:text-sm whitespace-nowrap">
                        {formatDate(post.createdAt)}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="container mx-auto px-4">
          <h2 className="text-xl md:text-3xl font-bold text-center text-gray-900 mb-8 md:mb-12">
            비즈니스 혁신을 경험하세요
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6">
            {[
              {
                icon: <FaChartLine />,
                title: "생산성 향상",
                description: "실시간 협업으로 40% 향상",
              },
              {
                icon: <FaCloud />,
                title: "클라우드 통합",
                description: "모든 데이터를 한 곳에서",
              },
              {
                icon: <FaShieldAlt />,
                title: "기업급 보안",
                description: "엔터프라이즈급 보안 제공",
              },
              {
                icon: <FaUsers />,
                title: "팀워크 강화",
                description: "효율적인 팀 커뮤니케이션",
              },
            ].map((item, index) => (
              <MemoizedStatCard
                key={index}
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </section>

        <section className="bg-gradient-to-r from-blue-600 to-blue-700 py-6 md:py-12">
          <div className="container mx-auto px-4 text-center text-white">
            <h2 className="text-xl md:text-3xl font-bold mb-3 md:mb-6">
              지금 바로 시작하세요
            </h2>
            <p className="text-sm md:text-xl mb-4 md:mb-8 text-blue-100">
              30일 무료 체험으로 Google Workspace를 경험해보세요
            </p>
            <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4">
              <a
                href="https://forms.gle/ZKdgbNnfZRoTrZq57"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-blue-600 px-4 md:px-8 py-1 md:py-3 text-xs md:text-base rounded-lg font-semibold hover:bg-blue-50"
              >
                무료 체험 시작하기
              </a>
              <a
                href="http://pf.kakao.com/_jyebn/chat"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-transparent border-2 border-white text-white px-4 md:px-8 py-1 md:py-3 text-xs md:text-base rounded-lg font-semibold hover:bg-white hover:text-blue-600"
              >
                상담 문의하기
              </a>
            </div>
          </div>
        </section>
      </main>

      <Suspense fallback={<LoadingSpinner />}>
        <Popup isOpen={isPopupOpen} onClose={closePopup}>
          <div className="popup-content max-w-md mx-auto p-3">
            <h2 className="text-xl font-bold mb-3 text-center text-blue-700">
              특별 혜택을 경험해보세요
            </h2>
            <p className="text-center leading-relaxed text-gray-700 text-sm">
              <span className="bg-yellow-100 px-1 py-0.5 rounded">
                지금 문의하시면
              </span>
              파트너사만의 특별한 혜택을 받을 수 있습니다!
              <br />
              <br />
              <span className="text-green-600 font-bold">
                파트너사 혜택: 특별 할인 + 1개월 무료 체험 + 맞춤형 도입 컨설팅
              </span>
              
              <br />
              <br />              
              <span className="italic text-xs text-gray-500">
                * 공식 홈페이지 직접 구매 시 무료 체험은 14일만 가능합니다
              </span>
            </p>
            <div className="mt-4 flex justify-center gap-2">
              <a
                href="http://pf.kakao.com/_jyebn/chat"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-1.5 bg-yellow-400 text-black rounded text-sm font-medium hover:bg-yellow-500 transition flex items-center"
              >
                <img
                  src="https://img.icons8.com/color/20/000000/kakaotalk.png"
                  alt="카카오 아이콘"
                  className="inline mr-1"
                />
                혜택 문의하기
              </a>
              <button
                onClick={dontShowForDay}
                className="px-3 py-1 bg-gray-600 text-white rounded text-xs"
              >
                오늘 그만보기
              </button>
              <button
                onClick={closePopup}
                className="px-3 py-1 bg-blue-600 text-white rounded text-xs"
              >
                닫기
              </button>
            </div>
          </div>
        </Popup>
      </Suspense>

      <div className="fixed bottom-2 right-2 md:bottom-4 md:right-4">
        <a
          href="http://pf.kakao.com/_jyebn/chat"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-yellow-400 text-black px-3 py-1 rounded-lg shadow-lg hover:bg-yellow-500 transition flex items-center text-xs"
        >
          <img
            src="https://img.icons8.com/color/24/000000/kakaotalk.png"
            alt="카카오 아이콘"
            className="inline mr-1"
          />
          카카오 문의하기
        </a>
      </div>
    </div>
  );
}

export default Home;
