import React, { useState } from 'react';
import { FaCheck, FaUsers, FaCalendarAlt, FaVideo, FaEnvelope, FaCloud, FaLock, FaEdit } from 'react-icons/fa';

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(true);

  const plans = [
    {
      name: 'Business Starter',
      price: isAnnual ? '6.60' : '7.20',
      features: [
        '맞춤 비즈니스 이메일',
        '화상 회의 (최대 100명)',
        '30GB 클라우드 스토리지',
        '보안 관리 기능',
        '표준 지원',
      ],
      highlight: false,
      color: 'blue'
    },
    {
      name: 'Business Standard',
      price: isAnnual ? '12.60' : '14.00',
      features: [
        '맞춤 비즈니스 이메일',
        '화상 회의 (최대 150명)',
        '2TB 클라우드 스토리지',
        '보안 관리 기능',
        '표준 지원',
        '회의 녹화 기능',
      ],
      highlight: true,
      color: 'blue'
    },
    {
      name: 'Business Plus',
      price: isAnnual ? '18.60' : '20.00',
      features: [
        '맞춤 비즈니스 이메일',
        '화상 회의 (최대 250명)',
        '5TB 클라우드 스토리지',
        '고급 보안 기능',
        '강화된 지원',
        'Vault 및 고급 엔드포인트',
      ],
      highlight: false,
      color: 'blue'
    },
    {
      name: 'Enterprise',
      price: '문의',
      features: [
        '맞춤 비즈니스 이메일',
        '화상 회의 (최대 500명)',
        '무제한 스토리지',
        '최고급 보안 기능',
        '프리미엄 지원',
        'eDiscovery 및 보관',
      ],
      highlight: false,
      color: 'gray',
      isEnterprise: true
    }
  ];

  const productivityFeatures = [
    {
      icon: <FaEdit className="w-8 h-8 text-blue-500" />,
      title: '문서 도구',
      description: 'Docs, Sheets, Slides로 실시간 공동 작업',
      features: [
        '실시간 공동 편집',
        '자동 저장 및 버전 관리',
        '오프라인 작업 지원',
        '스마트 작성 도구'
      ]
    },
    {
      icon: <FaVideo className="w-8 h-8 text-blue-500" />,
      title: 'Google Meet',
      description: '안전한 화상 회의 및 음성 통화',
      features: [
        '최대 500명 참석 가능',
        '화면 공유 및 녹화',
        '실시간 자막',
        '노이즈 캔슬링'
      ]
    },
    {
      icon: <FaCalendarAlt className="w-8 h-8 text-blue-500" />,
      title: 'Calendar',
      description: '스마트한 일정 관리 도구',
      features: [
        '일정 자동 조율',
        '회의실 예약',
        '외부 캘린더 통합',
        '스마트 알림'
      ]
    },
    {
      icon: <FaCloud className="w-8 h-8 text-blue-500" />,
      title: 'Drive',
      description: '안전한 클라우드 스토리지',
      features: [
        '파일 실시간 공유',
        '강력한 검색 기능',
        '백업 및 동기화',
        '접근 권한 관리'
      ]
    }
  ];

  const productivityComparison = {
    categories: [
      {
        name: '생산성 도구',
        features: [
          {
            name: '이메일',
            starter: '맞춤 이메일',
            standard: '맞춤 이메일',
            plus: '맞춤 이메일 + eDiscovery',
            enterprise: '맞춤 이메일 + 고급 eDiscovery'
          },
          {
            name: '화상 회의',
            starter: '최대 100명',
            standard: '최대 150명 + 녹화',
            plus: '최대 250명 + 출석 체크',
            enterprise: '최대 500명 + 실시간 번역'
          },
          {
            name: '문서 도구',
            starter: '기본 기능',
            standard: '고급 기능',
            plus: '고급 기능 + 승인 워크플로우',
            enterprise: '맞춤형 워크플로우'
          }
        ]
      },
      {
        name: '협업 도구',
        features: [
          {
            name: 'Google Drive',
            starter: '30GB/사용자',
            standard: '2TB/사용자',
            plus: '5TB/사용자',
            enterprise: '무제한'
          },
          {
            name: 'Calendar',
            starter: '기본 기능',
            standard: '회의실 예약',
            plus: '리소스 분석',
            enterprise: '고급 분석'
          },
          {
            name: 'Chat',
            starter: '기본 채팅',
            standard: '룸 + 파일 공유',
            plus: '고급 검색',
            enterprise: '커스텀 보존 정책'
          }
        ]
      }
    ]
  };

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
      {/* 헤더 섹션 */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          비즈니스에 맞는 플랜을 선택하세요
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          모든 플랜에는 보안 기능과 협업 도구가 포함되어 있습니다
        </p>

        {/* 결제 주기 토글 */}
        <div className="flex items-center justify-center gap-4 mb-8">
          <span className={`text-sm ${!isAnnual ? 'text-gray-600' : 'text-gray-400'}`}>월간 결제</span>
          <button
            className="relative w-16 h-8 bg-blue-600 rounded-full transition"
            onClick={() => setIsAnnual(!isAnnual)}
          >
            <div className={`absolute w-6 h-6 bg-white rounded-full transition-transform ${isAnnual ? 'translate-x-9' : 'translate-x-1'} top-1`} />
          </button>
          <div className="flex items-center gap-2">
            <span className={`text-sm ${isAnnual ? 'text-gray-600' : 'text-gray-400'}`}>연간 계약</span>
            <span className="text-sm text-green-600 font-semibold">(10% 할인)</span>
          </div>
        </div>
      </div>

      {/* 요금제 그리드 */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`bg-white rounded-xl shadow-lg overflow-hidden ${
                plan.highlight ? 'ring-2 ring-blue-500' : ''
              }`}
            >
              {plan.highlight && (
                <div className="bg-blue-500 text-white text-center py-2 text-sm font-semibold">
                  가장 인기있는 플랜
                </div>
              )}
              <div className="p-8">
                <h3 className="text-xl font-bold text-gray-900 mb-4">{plan.name}</h3>
                <div className="mb-6">
                  {plan.price === '문의' ? (
                    <div className="text-2xl font-bold text-gray-900">가격 문의</div>
                  ) : (
                    <div className="flex items-baseline">
                      <span className="text-3xl font-bold text-gray-900">USD {plan.price}</span>
                      <span className="text-gray-600 ml-2">사용자/월</span>
                    </div>
                  )}
                </div>
                <button
                  className={`w-full ${
                    plan.isEnterprise
                      ? 'bg-gray-600 hover:bg-gray-700'
                      : 'bg-blue-600 hover:bg-blue-700'
                  } text-white py-3 px-6 rounded-lg font-semibold transition-colors duration-200`}
                >
                  {plan.isEnterprise ? '영업팀 문의하기' : '무료로 시작하기'}
                </button>
                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start">
                      <FaCheck className={`w-5 h-5 ${plan.isEnterprise ? 'text-gray-500' : 'text-blue-500'} mt-0.5 mr-3 flex-shrink-0`} />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 생산성 및 공동작업 비교 섹션 */}
      <div className="max-w-7xl mx-auto mt-24">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            생산성 및 공동작업 도구 비교
          </h2>
          <p className="text-xl text-gray-600">
            버전별 기능을 비교하여 최적의 선택을 하세요
          </p>
        </div>

        {productivityComparison.categories.map((category, categoryIndex) => (
          <div key={categoryIndex} className="mb-16">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">{category.name}</h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left py-4 px-6 bg-gray-50 w-1/5">기능</th>
                    <th className="text-left py-4 px-6 bg-gray-50">Starter</th>
                    <th className="text-left py-4 px-6 bg-gray-50">Standard</th>
                    <th className="text-left py-4 px-6 bg-gray-50">Plus</th>
                    <th className="text-left py-4 px-6 bg-gray-50">Enterprise</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {category.features.map((feature, featureIndex) => (
                    <tr key={featureIndex} className="border-b border-gray-200">
                      <td className="py-4 px-6 font-medium">{feature.name}</td>
                      <td className="py-4 px-6">{feature.starter}</td>
                      <td className="py-4 px-6">{feature.standard}</td>
                      <td className="py-4 px-6">{feature.plus}</td>
                      <td className="py-4 px-6">{feature.enterprise}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}

        <div className="text-center mt-12">
          <button className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200 shadow-md">
            자세히 알아보기
          </button>
        </div>
      </div>

      {/* 부가 정보 섹션 */}
      <div className="max-w-4xl mx-auto mt-16 text-center">
        <div className="bg-blue-50 rounded-2xl p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            더 자세한 정보가 필요하신가요?
          </h2>
          <p className="text-gray-600 mb-6">
            전문 컨설턴트가 귀사에 가장 적합한 플랜을 추천해드립니다.
          </p>
          <div className="flex justify-center gap-4">
            <button className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200">
              상담 신청하기
            </button>
            <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold border-2 border-blue-600 hover:bg-blue-50 transition-colors duration-200">
              자세히 알아보기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;