import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, remove } from "firebase/database";
import { useAuth } from "../../components/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import "../../css/Board.css";

const Board = ({ type: propType }) => {
  const { type: paramType } = useParams(); // URL에서 게시판 타입 가져오기
  const type = propType || paramType; // propType이 우선, 없으면 paramType 사용
  const [posts, setPosts] = useState([]); // 게시글 목록
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [boardTypeName, setBoardTypeName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Firebase에서 데이터 읽기
  useEffect(() => {
    if (!type) {
      navigate("/board/notice"); // Redirect to default type
    }

    if (type) {
      const boardTypeRef = ref(database, `boardTypes/${type}`);
      onValue(boardTypeRef, (snapshot) => {
        const data = snapshot.val();

        if (data) {
          setBoardTypeName(data);
        }
      });
    }

    const postsRef = ref(database, `posts/${type}`); // 게시판 타입별 데이터

    onValue(postsRef, (snapshot) => {
      const data = snapshot.val();
      const postList = data
        ? Object.entries(data)
            .reverse()
            .map(([id, value]) => ({ id, ...value }))
        : [];
      setPosts(postList);
    });
  }, [type, navigate]);

  // 게시글 삭제
  const deletePost = (id) => {
    if (window.confirm("정말로 삭제하시겠습니까?") === true) {
      const postRef = ref(database, `posts/${type}/${id}`);
      remove(postRef).catch((error) => console.error("글 삭제 중 오류:", error));
    }
  };

  // 페이지네이션 계산 로직 추가
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // 페이지 변경 함수
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // 이전/다음 페이지 함수
  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <section className="bg-white py-8 px-4 sm:px-6 lg:px-8">
      <div className="board-container">
        {/* 헤더 섹션 */}
        <div className="mb-8">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold text-gray-900">{boardTypeName}</h2>
            {user && (
              <button
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                onClick={() => navigate(`/board/${type}/add`)}
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                글쓰기
              </button>
            )}
          </div>
          <p className="mt-2 text-sm text-gray-500">
            {boardTypeName}의 최신 소식을 확인하세요.
          </p>
        </div>

        {/* 게시물 리스트 - 스크롤 가능하도록 수정 */}
        <div className="bg-white shadow overflow-hidden rounded-lg">
          <div className="overflow-x-auto"> {/* 스크롤을 위한 wrapper 추가 */}
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-12 sm:w-16">
                    번호
                  </th>
                  <th scope="col" className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    제목
                  </th>
                  <th scope="col" className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">
                    작성자
                  </th>
                  <th scope="col" className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">
                    작성일
                  </th>
                  <th scope="col" className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24"> {/* w-16에서 w-20으로 변경 */}
                    조회수
                  </th>
                  {(user || isAdmin) && (
                    <th scope="col" className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-12 sm:w-16">
                      관리
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentPosts.map((post, index) => (
                  <tr
                    key={post.id}
                    className="hover:bg-gray-50 transition-colors duration-200"
                  >
                    <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-500">
                      {posts.length - index}
                    </td>
                    <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm">
                      <div className="flex flex-col sm:flex-row sm:items-center">
                        <button
                          onClick={() => navigate(`/board/${type}/${post.id}`)}
                          className="text-blue-600 hover:text-blue-900 font-medium transition-colors duration-200 text-left"
                        >
                          {post.title}
                        </button>
                        <div className="flex items-center mt-1 sm:hidden text-xs text-gray-500">
                          <span>{post.author} · </span>
                          <span className="ml-1">{post.createdAt} · </span>
                          <span className="ml-1">조회 {post.views}</span>
                        </div>
                      </div>
                    </td>
                    <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {isAdmin ? "관리자" : post.author}
                    </td>
                    <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {post.createdAt}
                    </td>
                    <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-30"> {/* w-20 추가 */}
                      {post.views}
                    </td>
                    {(user || isAdmin) && (
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-500">
                        {user && (user.email === post.author || isAdmin) && (
                          <button
                            onClick={() => deletePost(post.id)}
                            className="text-red-600 hover:text-red-900 font-medium transition-colors duration-200"
                          >
                            삭제
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지네이션 */}
        <div className="mt-4 flex justify-center">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
                currentPage === 1
                  ? 'text-gray-300 cursor-not-allowed'
                  : 'text-gray-500 hover:bg-gray-50'
              }`}
            >
              이전
            </button>
            
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                    : 'bg-white text-gray-500 hover:bg-gray-50'
                }`}
              >
                {index + 1}
              </button>
            ))}
            
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
                currentPage === totalPages
                  ? 'text-gray-300 cursor-not-allowed'
                  : 'text-gray-500 hover:bg-gray-50'
              }`}
            >
              다음
            </button>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Board;
