import React from 'react';
import { FaCloud, FaLock, FaUsers, FaChartLine } from 'react-icons/fa';

const Overview = () => {
  const features = [
    {
      icon: <FaCloud className="w-12 h-12 text-blue-500" />,
      title: '클라우드 기반 솔루션',
      description: '언제 어디서나 접근 가능한 클라우드 기반의 업무환경을 제공합니다.'
    },
    {
      icon: <FaLock className="w-12 h-12 text-blue-500" />,
      title: '엔터프라이즈급 보안',
      description: '데이터 손실 방지와 고급 보안 기능으로 기업 정보를 안전하게 보호합니다.'
    },
    {
      icon: <FaUsers className="w-12 h-12 text-blue-500" />,
      title: '실시간 협업',
      description: '팀원들과 실시간으로 문서를 공유하고 편집할 수 있습니다.'
    },
    {
      icon: <FaChartLine className="w-12 h-12 text-blue-500" />,
      title: '생산성 향상',
      description: '통합된 도구로 업무 효율성과 팀 생산성을 극대화합니다.'
    }
  ];

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8">
      {/* 헤더 섹션 */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          Google Workspace 서비스 개요
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          기업의 디지털 전환을 위한 최적의 솔루션, Google Workspace를 소개합니다.
          안전하고 효율적인 협업 환경으로 비즈니스 혁신을 경험하세요.
        </p>
      </div>

      {/* 주요 기능 그리드 */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex flex-col items-center text-center">
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 하단 CTA 섹션 */}
      <div className="max-w-4xl mx-auto mt-16 text-center">
        <div className="bg-blue-50 rounded-2xl p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Google Workspace와 함께 시작하세요
          </h2>
          <p className="text-gray-600 mb-6">
            지금 바로 Google Workspace를 도입하여 비즈니스 혁신을 경험하세요.
          </p>
          <a 
            href="https://forms.gle/ZKdgbNnfZRoTrZq57" 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-block"
          >
            <button className="bg-blue-500 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-600 transition-colors duration-300">
              무료 체험 시작하기
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Overview;