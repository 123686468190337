// Firebase v9 compat 패키지 사용
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD3fR8jKjLHYw_fKhDndMMHjwehRpcKqeE",
    authDomain: "workway-5f56c.firebaseapp.com",
    databaseURL: "https://workway-5f56c-default-rtdb.firebaseio.com",
    projectId: "workway-5f56c",
    storageBucket: "workway-5f56c.firebasestorage.app",
    messagingSenderId: "575785467162",
    appId: "1:575785467162:web:0ddec2367edd0b444600a3",    
  };

// Firebase 초기화
const app = initializeApp(firebaseConfig);

const database = getDatabase(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(app);

export { app, database,auth,googleProvider };